// Library
import Image from "next/image";
import Link from "next/link";
import { capitalize } from "../../../helpers/capitalize";
import { translateCategories } from "@/helpers/translateCategories";
import Ellipsis from "@/components/ui/Ellipsis/Ellipsis";

export default function CourseCard({ course, ...props }) {
    return (
        <Link href={`/course/${course.slug}`}>
            <div className="bg-gray-100 dark:bg-bgDark-840 rounded-card hover:scale-[0.98] overflow-hidden border dark:border-borderDark hover:border-primary dark:hover:border-primary duration-150 h-full">
                <div className="flex p-5 gap-5 h-full">
                    {/* Image */}
                    <div className="relative">
                        {/* <div className="md:block hidden">
                            <Image
                                src={course.socialImage}
                                alt="Image"
                                layout="fill"
                                className="object-cover"
                            />
                        </div>
                        <div className="md:hidden block aspect-w-16 aspect-h-9">
                            <Image
                                src={course.socialImage}
                                alt="Image"
                                layout="fill"
                                className="object-cover"
                            />
                        </div> */}
                        <div className="rounded-[17.544%] overflow-hidden aspect-1 w-[50px] h-[50px]">
                            <Image
                                src={course.image}
                                alt={course.name}
                                width={50}
                                height={50}
                            />
                        </div>
                    </div>

                    {/* Details */}
                    <div className="flex-1 flex flex-col h-full">
                        {/* Title */}
                        <div className="bold text-black dark:text-white">
                            {course.name}
                        </div>

                        {course.isBelievemyOriginals && (
                            <div className="text-primary font-sourceSans text-sm">
                                Believemy Originals.
                            </div>
                        )}

                        {/* Summary */}
                        <div className="text-gray-500 dark:text-lightBlue mt-3 flex-1 text-sm leading-relaxed">
                            <Ellipsis maxLines="3" disableReadMore>
                                {course.summary}
                            </Ellipsis>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}
